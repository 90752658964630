import { Footer, SEO } from 'components';
import { motion } from 'framer-motion';
import { FormattedMessage, Link, useIntl } from 'gatsby-plugin-intl';
import PageTransition from 'gatsby-v2-plugin-page-transitions';
import React from 'react';
import { cta, findyourfit, spacing, titles, utils } from 'style';

const FindYourFit: React.FC = () => {
  const intl = useIntl();

  return (
    <PageTransition>
      <SEO
        title={intl.formatMessage({ id: 'findYourFitSeo' })}
        url="https://find-your-fit.eu/en/find-your-fit"
        urlEN="https://find-your-fit.eu/en/find-your-fit"
        urlDE="https://find-your-fit.eu/de/find-your-fit"
        urlIT="https://find-your-fit.eu/it/find-your-fit"
        urlNL="https://find-your-fit.eu/nl/find-your-fit"
        urlFR="https://find-your-fit.eu/fr/find-your-fit"
      />
      <div css={utils.wrapper}>
        <section css={[findyourfit.root]}>
          <motion.h1
            css={[titles.secondary, findyourfit.title]}
            animate={{ y: [40, 0], opacity: [0, 1] }}
            transition={{ duration: 1.5, ease: [0.22, 1, 0.36, 1] }}
          >
            <FormattedMessage id="findYourFitTitlePartOne" />
            <span css={utils.fontFamilySansAlt}>
              {' '}
              <FormattedMessage id="findYourFitTitlePartTwo" />
            </span>{' '}
            <FormattedMessage id="findYourFitTitlePartThree" />
          </motion.h1>
          <motion.div
            css={findyourfit.wrapper}
            animate={{ y: [40, 0], opacity: [0, 1] }}
            transition={{ delay: 0.5, duration: 1.5, ease: [0.22, 1, 0.36, 1] }}
          >
            <div css={findyourfit.items}>
              <section css={findyourfit.item}>
                <header css={findyourfit.itemHeader}>
                  <div css={findyourfit.itemVisual}>
                    <img
                      src={require('assets/images/find-your-fit/nike-bra-light-support.jpg')}
                      alt=""
                    />
                  </div>
                  <h2 css={[titles.tertiary, findyourfit.itemTitle]}>
                    <FormattedMessage id="light" />
                    <br />
                    <span css={utils.fontFamilySansAlt}>
                      <FormattedMessage id="support" />
                    </span>
                  </h2>
                </header>
                <div css={findyourfit.itemContent}>
                  <p
                    css={[
                      utils.typeLarge,
                      spacing.bottom.large,
                      utils.fontFamilySansAlt,
                    ]}
                  >
                    <FormattedMessage id="lightDefinition" />
                  </p>
                  <p css={[utils.typeLarge, utils.fontFamilySansAlt]}>
                    <FormattedMessage id="lightSportsDefinition" />
                  </p>
                </div>
              </section>

              <section css={findyourfit.item}>
                <header css={findyourfit.itemHeader}>
                  <img
                    src={require('assets/images/find-your-fit/nike-bra-medium-support.jpg')}
                    alt=""
                  />
                  <h2 css={[titles.tertiary, findyourfit.itemTitle]}>
                    <FormattedMessage id="medium" />
                    <br />
                    <span css={utils.fontFamilySansAlt}>
                      {' '}
                      <FormattedMessage id="support" />
                    </span>
                  </h2>
                </header>

                <div css={findyourfit.itemContent}>
                  <p
                    css={[
                      utils.typeLarge,
                      spacing.bottom.large,
                      utils.fontFamilySansAlt,
                    ]}
                  >
                    <FormattedMessage id="mediumDefinition" />
                  </p>

                  <p css={[utils.typeLarge, utils.fontFamilySansAlt]}>
                    <FormattedMessage id="mediumSportsDefinition" />
                  </p>
                </div>
              </section>

              <section css={findyourfit.item}>
                <header css={findyourfit.itemHeader}>
                  <img
                    src={require('assets/images/find-your-fit/nike-bra-high-support.jpg')}
                    alt=""
                  />
                  <h2 css={[titles.tertiary, findyourfit.itemTitle]}>
                    <FormattedMessage id="high" />
                    <br />
                    <span css={utils.fontFamilySansAlt}>
                      <FormattedMessage id="support" />
                    </span>
                  </h2>
                </header>
                <div css={findyourfit.itemContent}>
                  <p
                    css={[
                      utils.typeLarge,
                      spacing.bottom.large,
                      utils.fontFamilySansAlt,
                    ]}
                  >
                    <FormattedMessage id="highDefinition" />
                  </p>
                  <p css={[utils.typeLarge, utils.fontFamilySansAlt]}>
                    <FormattedMessage id="highSportsDefinition" />
                  </p>
                </div>
              </section>
            </div>
          </motion.div>
          <Link to="/measure-type" css={cta.button}>
            <FormattedMessage id="getStartedCta" />
          </Link>
        </section>
      </div>
      <Footer />
    </PageTransition>
  );
};

export default FindYourFit;
